import OktaAuth from "@okta/okta-auth-js";

export const oktaConfig = {
    issuer: `https://signin.kering.com/oauth2/default`,
    clientId: "0oadp104f8wzzje87417",
    redirectUri: window.location.hostname === 'competitorstool.globaltech.gucci' ? 'https://competitorstool.globaltech.gucci/login/callback' :
        (window.location.hostname === 'hrintegration.globalbit.gucci' ? 'https://hrintegration.globalbit.gucci/competitorstool/login/callback' :
            'http://localhost:3000/login/callback/'),
    postRedirectUri: window.location.hostname === 'competitorstool.globaltech.gucci' ? 'https://competitorstool.globaltech.gucci/login/callback' :
        (window.location.hostname === 'hrintegration.globalbit.gucci' ? 'https://hrintegration.globalbit.gucci/competitorstool/login/callback' :
            'http://localhost:3000/login/callback/'),

    scopes: ["openid", "profile", "email", "offline_access"],
    pkce: true,
    tokenManager: {
        autoRenew: true
    }

};

export const oktaAuth = new OktaAuth(oktaConfig);