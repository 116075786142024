export const title = 'Competitors Tool'
export const multiselectStyle = {
    chips: {
        background: '#000',
        'font-size': '11px',
        'border-radius': '3px',
        'line-height': '4px',
        'margin-bottom': '1px'
    },
    multiselectContainer: {
        color: '#000',
    },
    searchBox: {
        'border': '1px solid #b6b6b6',
        'border-radius': '7px',
        'margin-top': '4px',
        cursor: 'pointer',
        padding: 5,
        height: '25px'
    }
}

export const customStylesModal = {
    content: {
        "z-index": 6,
        top: '10%',
        right: '4vw',
        left: 'auto',
        bottom: 'auto',
        width: window.innerWidth > 1000 ? '400px' : '90%',
        height: 'min-content',
        backgroundColor: 'white',
    }
};
export const customStylesConfirmModal = {
    content: {
        "z-index": 6,
        top: '10%',
        right: '4vw',
        left: 'auto',
        bottom: 'auto',
        width: window.innerWidth > 1000 ? '400px' : '90%',
        height: 'min-content',
        backgroundColor: 'white',
    }
};
export const brand_label = 'Brand'
export const brand_name_label = 'Brand Name'
export const comments_label = 'Comments'
export const fx_label = 'Fx'
export const lm_label = 'LM'
export const local_currency = 'Local Currency'
export const local_sales = 'Sales in Local Currency'
export const local_last_year_sales = 'LY Sales in Local Currency'
export const location_label = 'Location'
export const metrics_label = 'Metrics'
export const month_label = 'Month'
export const ratio_label = 'Ratio'
export const solar_calendar_label = 'Solar Calendar'
export const tm_vs_lm_label = '% TM VS LM'
export const ty_label = 'TY'
export const tm_label = 'TM'
export const vs_ly_label = 'vs.LY%'
export const ytd_label = 'YTD'