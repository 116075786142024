import React from 'react';
import Modal from 'react-modal';
import ModalFilters from './ModalFilters';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Service from '../services/Service';
import { Slide } from 'react-toastify';
import { customStylesModal } from '../global';

export default function ExportButtons(props) {

    const [modal, setModal] = React.useState(false)
    let service = new Service()

    const handleFile = (e, xlsxfile) => {
        if (e && e.target && e.target.value) {
            let tmp = e.target.value.split('.')
            let ext = tmp[tmp.length - 1]
            if (ext === 'xlsx') {
                let fd = new FormData()
                fd.append("file", xlsxfile)
                service.uploadFile(fd).then((response) => {
                    e.target.value = ''
                    toast.success(response.message, { transition: Slide, toastId: 'export_success' })
                    props.setLoading(true)
                    setTimeout(() => {
                        props.setLoading(false)
                    }, [1000])
                }).catch((err) => {
                    e.target.value = ''
                    if (err && err.response && err.response.data && err.response.data.message)
                        toast.error(err.response.data.message, { transition: Slide, toastId: 'export_error1' })
                    else if (err && err.response && err.response.message)
                        toast.error(err.response.message, { transition: Slide, toastId: 'export_error2' })
                    else if (err && err.message)
                        toast.error(err.message, { transition: Slide, toastId: 'export_error3' })
                    else
                        toast.error("Something went wrong", { transition: Slide, toastId: 'export_error4' })
                })
            } else {
                e.target.value = ''
                toast.error('You can upload only xlsx file!', {
                    background: "#00FF00 !important",
                    transition: Slide,
                    toastId: 'export_error0'
                })
            }

        }
    }

    const uploadF = () => {
        let f = document.getElementById('upload-file')
        f.click()
    }

    const closeAndReload = () => {
        setModal(false)
        props.setLoading(true)
        setTimeout(() => {
            props.setLoading(false)
        }, 1000)
    }
    const close = () => {
        setModal(false)
    }


    return (
        <div className="export" Style={'padding-right:0'}>

            <div className="export_button" for="upload-photo" onClick={() => uploadF()}>
                <input type="file" id="upload-file" Style={'visibility:hidden'} onChange={(e) => handleFile(e, e.target.files[0])} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" class="bi bi-cloud-upload" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383" />
                    <path fill-rule="evenodd" d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708z" />
                </svg>
            </div>

            {props.user && props.user.is_admin ? <div className="export_button ml-15p" onClick={props.exportSales}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" class="bi bi-file-earmark-text" viewBox="0 0 16 16" >
                    <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5" />
                    <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                </svg>
            </div>
                : ''}
            <div className="export_button ml-15p" Style={'margin-right:0'} onClick={() => setModal(true)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" class="bi bi-plus" viewBox="0 0 16 16">
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                </svg>
            </div>

            <Modal
                ariaHideApp={false}
                isOpen={modal}
                onRequestClose={() => setModal(false)}
                style={customStylesModal}
            >
                <div>
                    <ModalFilters date={props.date} locations={props.locations} brands={props.brands} locationDescription={props.locationDescription} location={props.location} closeAndReload={closeAndReload} close={close} />
                </div>
            </Modal >
            <ToastContainer stacked containerId="containerA" />
        </div>)
}