import { Route, Routes, useNavigate } from "react-router-dom";
import { LoginCallback, Security } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { oktaAuth } from "./config";
import Homepage from "./views/Homepage";
import LoginManager from "./LoginManager";
import { ErrorComponent } from "./views/ErrorComponent";

const MyRoutes = () => {

	const history = useNavigate();
	const originalUri = async (_oktaAuth, originalUri) => {
		history(toRelativeUrl(originalUri || "/", window.location.origin));
	};

	return (
		<Security oktaAuth={oktaAuth} restoreOriginalUri={originalUri} >
			<Routes >
				<Route path="/login/callback" element={<LoginCallback errorComponent={ErrorComponent} />} />
				<Route path="/" exact={true} element={<Homepage />} />
			</Routes>
		</Security>
	);
};
export default MyRoutes;