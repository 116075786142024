import React, { useEffect, useState } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import "react-datepicker/dist/react-datepicker.css";
import Service from '../services/Service';
import { Slide, toast } from 'react-toastify';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip'
import { brand_label, comments_label, customStylesConfirmModal, customStylesModal, local_currency, local_last_year_sales, local_sales, location_label, month_label, solar_calendar_label } from '../global';
import Modal from 'react-modal';
export default function ModalFilters(props) {
    const [filters, setFilters] = useState({})
    const [locations, setLocations] = useState(props.locations ? props.locations : [])
    const [solar_calendar, setSolarCalendar] = useState(true)
    const [selectedLocation, setSelectedLocation] = React.useState(props.location && props.label ? props.location.label : '')
    const [type, selectType] = React.useState('')
    const [brands, setBrands] = useState([])
    const [message, setMessage] = useState('')
    const [note_message, setNoteMessage] = useState('')
    const [confirm_message, setConfirmMessage] = useState('')
    const locRef = React.createRef();
    const brandRef = React.createRef();
    const [startDate, setStartDate] = useState(props.date ? props.date : null);
    const [confirmPopup, setConfirmPopup] = useState(false);
    const [currencies, setCurrencies] = useState([]);
    let service = new Service()
    var typingTimer;                //timer identifier
    var doneTypingInterval = 1000;

    const selectStyle = {
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected || state.isFocused ? '#fff!important' : 'black',
            backgroundColor: state.isSelected || state.isFocused || state.isDisabled ? '#4b000a' : 'white',
            padding: '4px 2px !important',
            cursor: 'pointer'
        }),
        control: () => ({
            // none of react-select's styles are passed to <Control />
            width: '100%',
            display: 'flex',
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';

            return { ...provided, opacity, transition };
        }
    }
    const check = (e) => {
        return (/^\d*(\.)$/.test(e) || /^\d*$/.test(e) || /^\d*(\.\d+)*$/.test(e))
    }
    const setField = (e, name) => {
        if (name === 'sales' || name === 'last_year_sales') {
            if (check(e)) {
                let f = filters
                f[name] = e.replace(',', '')
                setFilters({ ...f })
            }
        } else {
            let f = filters
            f[name] = e
            setFilters({ ...f })
        }
    }

    const handleChange = (e) => {
        setSelectedLocation(e)
        setField(null, 'brand')
        service.getBrandsByLocation(e.value).then((data) => {
            let br = []
            data.map((b) =>
                br.push({
                    label: b.brand,
                    value: b.id
                })
            )
            setBrands(br)
        })
    }
    const submit = () => {

        let obj = {}
        if (type === 'Comparable' || type === 'Not comparable')
            obj = {
                brand_id: filters['brand'].value,
                year: startDate.getFullYear(),
                month: startDate.getMonth() + 1,
                is_solar_calendar: solar_calendar,
                currency: filters['currency'].value,
                status: type,
                sales: parseFloat(filters['sales']).toFixed(2),
                last_year_sales: filters['last_year_sales'] ? parseFloat(filters['last_year_sales']).toFixed(2) : null,
                comments: filters['comments'] ?? null
            }
        else
            obj = {
                brand_id: filters['brand'].value,
                year: startDate.getFullYear(),
                month: startDate.getMonth() + 1,
                is_solar_calendar: solar_calendar,
                status: type,
                comments: filters['comments'] ?? null
            }
        service.insertSales(selectedLocation.value, obj).then(() => {
            toast.success('Sales inserita correttamente', { transition: Slide, toastId: 'sales_success' })
            props.closeAndReload()
        }).catch((err) => {
            if (err.response && err.response.data && err.response.data.message)
                toast.error(err.response.data.message, { transition: Slide, toastId: 'sales_error0' })
            else
                toast.error(err.message, { transition: Slide, toastId: 'sales_error1' })
        })

    }
    useEffect(() => {
        setSelectedLocation(props.location)
    }, [props.location])
    useEffect(() => {
        if (props.brands)
            setBrands(props.brands)
    }, [props.brands])
    useEffect(() => {
        service.getCurrencies().then((data) => {
            let currenciestmp = []
            data.map((t) => {
                currenciestmp.push({
                    label: t,
                    value: t
                })
            })
            setCurrencies(currenciestmp)
        })
    }, [])
    const closeModal = () => {
        setFilters({})
        selectType('')
        setSolarCalendar(false)
        setStartDate(null)
        setMessage('')
        props.close()
    }

    const checkCalendarAndSubmit = () => {
        if (Object.is(selectedLocation, null))
            toast.error("Please fill the location field!", { transition: Slide, toastId: 'location' })
        else if (!filters['brand'])
            toast.error("Please fill the brand field!", { transition: Slide, toastId: 'brand' })
        else if (!startDate)
            toast.error("Please fill the date field!", { transition: Slide, toastId: 'date' })
        else if (!type) {
            toast.error("Please select an option type!", { transition: Slide, toastId: 'type' })
            let tc = document.getElementById('type_container')
            if (tc)
                tc.className = 'type_container red_border_animation'
            setTimeout(() => {
                let tc = document.getElementById('type_container')
                if (tc)
                    tc.className = 'type_container'
            }, 5000);
        }
        else if (!filters['currency'] && (type === 'Comparable' || type === 'Not comparable'))
            toast.error("Please fill the currency field!", { transition: Slide, toastId: 'currency' })
        else if (!filters['sales'] && (type === 'Comparable' || type === 'Not comparable'))
            toast.error("Please fill the sales field!", { transition: Slide, toastId: 'sales' })
        else {

            let obj = {
                month: startDate.getMonth() + 1,
                year: startDate.getFullYear()
            }
            service.checkCalendarType(selectedLocation.value, filters['brand'].value, obj).then((data) => {
                if (!Object.is(data.is_solar_calendar, null) && !data.is_solar_calendar && solar_calendar || !Object.is(data.is_solar_calendar, null) && data.is_solar_calendar && !solar_calendar) {
                    let sel = solar_calendar ? '(solar)' : '(merchandising)'
                    setMessage('The calendar type selected ' + sel + ' doesn’t match the one selected for the previous month.')
                    setConfirmMessage('Are you sure you want to select it as calendar type?')
                    setNoteMessage('Note: if you select two different calendar types, you will not see the % TM vs LM.')
                    setConfirmPopup(true)
                }
                else
                    submit()
            })

        }
    }

    return (

        <div className='modal_filters_container'>
            <div>
                <div className='filter-label-cont' Style={confirmPopup ? 'text-align:center' : ''}>{confirmPopup ? 'Are you sure' : 'Add'}</div>

                <svg onClick={!confirmPopup ? closeModal : () => setConfirmPopup(false)} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor " class="bi bi-x close-button" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                </svg>
            </div>
            <hr className='separator' />
            {!confirmPopup ? <>
                <div Style={'overflow:auto;max-height:70vh'}>

                    <div className='modal-filters' Style={'position: relative;z-index: 10;'}>
                        <div className='title-label'>details</div>

                        <div className='filter' Style={'z-index: 9'}>
                            <label className='bold filter-label flex'>{location_label}</label>
                            <Select
                                placeholder={selectedLocation && selectedLocation.label ? selectedLocation.label : 'Select'}
                                onChange={handleChange}
                                options={locations}
                                styles={selectStyle}
                                id='input-select-modal'
                            />
                        </div>

                        <div className='filter' Style={'z-index: 9'}>
                            <label className='bold filter-label flex'> {brand_label}</label>

                            <Select
                                value={!Object.is(filters['brand'], null) ? filters['brand'] : 'Select'}
                                onChange={(e) => setField(e, 'brand')}
                                options={brands}
                                styles={selectStyle}
                                id='input-select-modal-brand'
                            />
                        </div>
                        <div className='filter' Style={'z-index: 10'}>
                            <div className='flex' Style={'align-items: flex-end;'}>
                                <label className='bold filter-label flex'> {month_label}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-calendar3 ml-3 mt-2" viewBox="0 0 16 16">
                                        <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857z" />
                                        <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                                    </svg>
                                </label>
                                <div className='flex calendar'>
                                    <input type='checkbox' checked={solar_calendar} onChange={() => setSolarCalendar(!solar_calendar)} />
                                    <div className='label'>{solar_calendar_label}</div>
                                </div>
                            </div>
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                            />

                        </div>
                    </div>
                    <div className='modal-filters' Style={'position: relative;z-index: 8;'}>

                        <div className='type_container' id='type_container'>
                            <div className={type === 'Comparable' ? 'selected_type' : 'unselected_type'} onClick={() => selectType('Comparable')}>Comparable</div>
                            <div className={type === 'Not comparable' ? 'selected_type' : 'unselected_type'} onClick={() => selectType('Not comparable')}>Not comparable</div>
                            <div className={type === 'Not present' ? 'selected_type' : 'unselected_type'} onClick={() => selectType('Not present')}>Not present</div>
                        </div>

                    </div>
                    {type === 'Comparable' || type === 'Not comparable' ?
                        <div className='modal-filters'>

                            <div className='flex' Style={'position:relative;z-index: 9'}>
                                <div className='filter' >
                                    <label className='bold filter-label flex'> {local_currency}</label>

                                    <Select
                                        value={!Object.is(filters['currency'], null) ? filters['currency'] : 'Select'}
                                        onChange={(e) => setField(e, 'currency')}
                                        options={currencies}
                                        styles={selectStyle}
                                        id='input-select-modal'
                                    />
                                </div>
                            </div>

                            <div className='flex' Style={'position:relative;z-index: 8'}>
                                <div className='filter'>
                                    <label className='bold filter-label flex'> {local_sales}</label>
                                    <div className='flex'>
                                        <input Style={'width: 100%;'} value={filters['sales'] ?? ''} type='text' className='modal-filter-input ' onChange={(e) => setField(e.target.value, 'sales')} />
                                        <svg data-tooltip-id="my-tooltip" data-tooltip-html='Please input the full sales amount and use “.” if decimals are available.<br/>
                                        Eg: 1230000.45 <br/> (not 12.3M or 12.300K)' xmlns="http://www.w3.org/2000/svg" width="17" height="15" fill="currentColor" class="bi bi-info-circle info_ico" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className='flex' Style={'position:relative;z-index:8'}>
                                < div className='filter' >
                                    <label className='bold filter-label flex'>{local_last_year_sales}</label>
                                    <div className='flex'>
                                        <input Style={'width: 100%;'} value={filters['last_year_sales'] ?? ''} type='text' className='modal-filter-input' onChange={(e) => setField(e.target.value, 'last_year_sales')} />

                                        <svg data-tooltip-id="my-tooltip" data-tooltip-html='Please input the full sales amount and use “.” if decimals are available.<br/>
                                        Eg: 1230000.45 <br/> (not 12.3M or 12.300K)' xmlns="http://www.w3.org/2000/svg" width="17" height="15" fill="currentColor" class="bi bi-info-circle info_ico" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                                        </svg>
                                    </div>
                                    <Tooltip
                                        id="my-tooltip"
                                        place={'left'}
                                        style={{ backgroundColor: "#000", color: "#fff", width: '150px', opacity: 1, zIndex: 11 }}
                                    />
                                </div>
                            </div>
                        </div> : ''}
                    <div className='modal-filters'>

                        <div className='filter' Style={'width:100%'}>
                            <label className='bold filter-label'> {comments_label}</label>
                            <textarea type='text' className='comments' onChange={(e) => setField(e.target.value, 'comments')} />
                        </div>

                    </div>
                </div>
                <div className='modal-filters' Style={'place-content: end;'}>
                    <div className='warn_text'>Note that if data have already been inserted, they will be overwritten.</div>
                    <div className='filter ' Style={'width:100%'}>
                        <button className='submit-button' onClick={checkCalendarAndSubmit}>Submit</button>
                    </div>
                </div>
            </> : <>
                <div className='modal-filters' Style={'place-content: end;'}>
                    <div className='label_text center'>{message}</div>
                    <div className='label_text center'>{note_message}</div>
                    <div className='label_text_bold center'>{confirm_message}</div>
                    <div className='flex_center mt-10' Style={'width:100%'}>
                        <button className='cancel-button' onClick={() => setConfirmPopup(false)}>Cancel</button>
                        <button className='submit-button' onClick={() => submit()}>Submit</button>
                    </div>
                </div>
            </>
            }
        </div >)
}